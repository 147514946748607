<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label> -->
            <v-select
              @option:selected="limitChanged"
              v-model="pagination.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-size-sm"
            />
            <label>entries per page</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button> -->
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mb-1"
                right
                size="sm"
                text="Export"
                variant="outline-primary"
              >
                <b-dropdown-item @click="downloadExcel">Excel</b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        :items="reportData"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(sr)="data">
          {{
            data.index + 1 + (pagination.currentPage - 1) * pagination.perPage
          }}
        </template>
        <template #cell(last_report_date)="data">
          {{ moment(data.item.last_report_date).format("DD/MM/YYYY") }}
        </template>

        <template #cell(statement_period)="data">
          From <b>{{ moment(data.item.dateFrom).format("DD/MM/YYYY") }}</b> To
          <b>{{ moment(data.item.dateTo).format("DD/MM/YYYY") }}</b>
        </template>

        <template #cell(balance_to_favor)="data">
          <b>{{
            data.item.balance_to_favor ? data.item.balance_to_favor : "N/A"
          }}</b>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            > -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              @change="onPageChange"
              v-model="pagination.currentPage"
              :total-rows="pagination.total"
              :per-page="pagination.perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :limit="15"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

import { GetContractOverview } from "@/apiServices/ReportsServices";
import moment from "moment";
import { downloadFromURL } from "@/utils/helpers";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    ToastificationContentVue,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASEURL,
      moment,
      roleOptions: [
        { label: "Admin", value: "admin" },
        { label: "Author", value: "author" },
        { label: "Editor", value: "editor" },
        { label: "Maintainer", value: "maintainer" },
        { label: "Subscriber", value: "subscriber" },
      ],

      planOptions: [
        { label: "Basic", value: "basic" },
        { label: "Company", value: "company" },
        { label: "Enterprise", value: "enterprise" },
        { label: "Team", value: "team" },
      ],

      statusOptions: [
        { label: "Pending", value: "pending" },
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
      ],
      pagination: {
        currentPage: 1,
        total: null,
        perPage: 10,
      },
      perPageOptions: [
        { value: 10, label: 10 },
        { value: 25, label: 25 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: "all", label: "All" },
      ],
      tableColumns: [
        {
          label: "SR",
          key: "sr",
          // sortable:true,
        },
        {
          label: "Type",
          key: "contract_nature",
          sortable: true,
        },
        {
          label: "Licensor",
          key: "company",
          sortable: true,
        },
        {
          label: "Name/Title",
          key: "movie_name",
          sortable: true,
        },
        {
          label: "Year",
          key: "year",
          sortable: true,
        },
        {
          label: "MG",
          key: "mgadvance",
        },
      ],
      reportData: [],
    };
  },

  methods: {
    limitChanged(val) {
      this.pagination.currentPage = 1;
      this.getReportData();
    },
    onPageChange() {
      this.$nextTick(() => {
        this.getReportData();
      });
    },
    async getReportData() {
      const response = await GetContractOverview({
        page: this.pagination.currentPage,
        limit: this.pagination.perPage,
      });
      if (!response.status) {
        return this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Data not found!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
      this.reportData = response.data.data.data;
      if (response.data.data.pagination.total) {
        this.pagination.total = response.data.data.pagination.total;
      }
      this.prepareRightsColumn();
    },

    async prepareRightsColumn() {
      return;
      let rightsData = [];
      this.reportData.length &&
        this.reportData.map((item) => {
          rightsData = [...rightsData, ...item.contract_approval];
        });

      let uniqueRights = rightsData.filter((value, index, self) => {
        return self.findIndex((v) => v.right_id === value.right_id) === index;
      });

      let newcols = [];
      if (uniqueRights.length) {
        uniqueRights.map((ur) => {
          newcols.push({
            label: ur.right_name,
            key: ur.licensor_share,
            sortable: true,
          });
        });
      }

      const newColsArr = this.tableColumns.concat(newcols);
      this.tableColumns = newColsArr;
    },

    async downloadExcel() {
      fetch(this.BASE_URL + "/report/downloadContractOverviewExcel", {
        headers: {
          "content-type": "application/Json",
          authorization: localStorage.getItem("access_token"),
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var _url = window.URL.createObjectURL(blob);
          downloadFromURL(_url, "ContractOverview", "xlsx");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  beforeMount() {
    this.getReportData();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
